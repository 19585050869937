import request from '@http';

// 账单列表
export function getBills(params) {
    return request({
        method: 'GET',
        url: '/api/gzf/bill/',
		params
    })
}

// 2022/04/22 王江毅 获取杂费账单列表
export function getOtherBills(params) {
    return request({
        method: 'GET',
        url: '/api/gzf/other/bill/list',
        params
    })
}

// 2022/04/27 王江毅 导出杂费账单
export function otherBillExport(params) {
    return request({
        method: 'GET',
        url: '/api/gzf/other/bill/list/export',
        responseType: 'blob',
        params
    })
}

// 2022/04/27 王江毅 导出房租账单
export function rebillExport(params) {
    return request({
        method: 'GET',
        url: '/api/gzf/rebill/export',
        responseType: 'blob',
        params
    })
}

// 2022/05/17 王江毅 账单详情
export function paybillDetails(uuid) {
    return request({
        method: 'GET',
        url: `/api/gzf/paybill/details/${uuid}`,
    })
}

// 2022/05/18 王江毅 根据房源uuid和人员uuid查询房租未缴账单
export function getBillsUnpaidListByApartment(uuid,params) {
    return request({
        method: 'GET',
        url: `/api/gzf/baseinfo/leasor/${uuid}/bills/rental/apartment/unpaid`,
        params
    })
}

// 2022/05/18 王江毅 根据房源uuid和人员uuid查询房租已缴账单
export function getBillsPaidListByApartment(uuid,params) {
    return request({
        method: 'GET',
        url: `/api/gzf/baseinfo/leasor/${uuid}/bills/rental/apartment/paid`,
        params
    })
}
// 2022/05/18 王江毅 根据房源uuid和人员uuid查询杂费未缴账单
export function getOtherBillsUnpaidListByApartment(uuid,params) {
    return request({
        method: 'GET',
        url: `/api/gzf/other/bill/${uuid}/unpaid/apartment`,
        params
    })
}

// 2022/05/18 王江毅 根据房源uuid和人员uuid查询杂费已缴账单
export function getOtherBillsPaidListByApartment(uuid,params) {
    return request({
        method: 'GET',
        url: `/api/gzf/other/bill/${uuid}/paid/apartment`,
        params
    })
}

// 2022/05/18 王江毅 根据合同uuid查询账单
export function getPaybillsByContract(uuid,params) {
    return request({
        method: 'GET',
        url: `/api/gzf/paybill/bycontract/${uuid}`,
        params
    })
}

// 2022/05/23 王江毅 账单管理列表
//2024/04/07 张晓瑜修改账单管理列表接口
export function getPaybillList(params) {
    return request({
        method: 'GET',
        url: `/api/gzf/paybill/page/list`,
        params
    })
}

// 2024/04/08 张晓瑜 账单管理导出接口更换 原接口api/gzf/paybill/rental/export
export function paybillRentalExport(params) {
    return request({
        method: 'GET',
        url: '/api/gzf/paybill/export/list',
        responseType: 'blob',
        params
    })
}

// 2022/08/04 王江毅 根据;分割的uuid列表查询
export function getPaybillUuidsList(params) {
    return request({
        method: 'GET',
        url: `/api/gzf/paybill/uuids/payBill`,
        params
    })
}
// 2022/09/30 王江毅 账单金额详情
export function getAmountData(params) {
    return request({
        method: 'GET',
        url: `/api/gzf/paybill/pay/details/bill/amount`,
        params
    })
}

// 2022/10/25 王江毅 批量核销查询金额
export function getCancelAmount(params) {
    return request({
        method: 'GET',
        url: `/api/gzf/paybill/cancel/amount/get`,
        params
    })
}

// 2022/10/25 王江毅 批量核销金额
export function cancelBills(data) {
    return request({
        method: 'POST',
        url: `/api/gzf/paybill/cancel/bills`,
        data
    })
}

// 2023/05/16 王江毅 根据租户uuid和房间uuid 查询合同列表 合同uuid非必填项，有则过滤，无责不过滤
export function getContractListByUserAndHouse(params) {
    return request({
        method: 'GET',
        url: `/api/gzf/contract/leasor/apartment/list`,
        params
    });
}

// 2023/05/16 王江毅 转移押金账单到合同
export function updateDepositToContract(params) {
    return request({
        method: 'GET',
        url: `/api/gzf/contract/deposit/update`,
        params
    });
}
// 2024-11-11-陈守亮-模板样式下载
export function handleExportDownload(params) {
    // 后台说code是固定值，写死即可
    return request({
        method: 'get',
        url: `/api/gzf/data/code?code=800201`,
        // responseType: 'blob',
        params
    })
}
// 2024-11-11-陈守亮-模板批量导出
export function getPaybillListApi(data) {

    return request({
        method: 'post',
        url: '/api/gzf/other/bill/export/list',
        responseType: 'blob',
        data
    })
}
